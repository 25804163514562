.white {
    color: white ;
}

.bg-blue {
    background-color: rgb(0, 68, 255) !important;
}

.text-big {
    font-size: 20px !important;
}

.bg-image-nous {
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url("assets/images/bg/banner_small.jpg");
}

.no-bg-image-nous {
    background-image: none !important;
}